import services from "../axios/dbManag";
import { msalInstance } from "@/authConfig";
export const checkTokenFromFCH = (token) => {
  services.dbManagControlloTokenFCH.interceptors.request.use((config) => {
    config.headers["Authorization"] = "bearer " + token;
    return config;
  });
  return services.dbManagControlloTokenFCH
    .post("/login")
    .then((res) => {
      console.log(res);
      return res.status;
    })
    .catch(async (e) => {
      console.log(e.response.status);

      return e.response.status;
    });
};
